import React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { StyledBlock, StyledContainer, StyledH2, StyledText } from "../components/styled"

const ShareVisionPage = ({ changeHeaderColor, defaultHeaderColor }: any) => {
	useEffect(() => {
		changeHeaderColor(defaultHeaderColor)
	}, [])

	return (
		<Layout>
			<SEO title="ShareVision" />
			<StyledBlock subPage>
				<StyledContainer>
					<StyledH2>Share vision</StyledH2>
					<StyledText>Lorem ipsum dolor sit, amet consectetur adipisicing elit!</StyledText>
				</StyledContainer>
			</StyledBlock>
		</Layout>
	)
}

const mapStateToProps = ({ defaultHeaderColor }: any) => ({ defaultHeaderColor })

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeHeaderColor: (payload: boolean) => dispatch({ type: `CHANGE_HEADER_COLOR`, payload }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareVisionPage)
